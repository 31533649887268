import React from 'react'
import Helmet from 'react-helmet'
import map from 'lodash/map'
import round from 'lodash/round'
import size from 'lodash/size'
import slice from 'lodash/slice'

import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid, Typography } from '@material-ui/core'

import MarkdownRenderer from '../components/MarkdownRenderer'
import Layout from '../components/Layout'

const faqs = [
	{
		question: 'Sounds too good to be true. How do you do this?',
		answer:
			'Firstly, Tyto has a very powerful task API for all the regular integrations you could imagine. But for the multi-system, multi-client, extra complex workflows, we make use M2North, our sister company— who have been the glue to over 35,000 of the biggest (and smallest) companies for the last 19 years.',
	},
	{
		question: 'Sounds expensive. How much does this cost?',
		answer:
			"You'd be surprised how affordable these integrations are. Our goal is to provide real-world solutions for companies. We charge a monthly subscription fee for the enterprise integration.",
	},
	{
		question: 'Sounds painful. How long does a typical integration take?',
		answer:
			'A typical integration takes no more than a few weeks to get up-and-running, with a complete, robust solution taking 1-2 months.',
	},
	{
		question: 'Which systems do you integrate with?',
		answer:
			'SAP, Baan, JD Edwards, as well as many fully bespoke solutions. We integrate via a long list of methods, including SOAP, API, FTP, SFTP, email, SQL (via staging servers) and even via a fully-configurable on-site custom collector.',
	},
	{
		question: 'How do I get started?',
		answer: 'Contact us at help@tyto.me for a no-obligation 1-on-1 chat. ',
	},
]

const useStyles = makeStyles(
	theme => ({
		root: {
			paddingBottom: theme.spacing(8),
			paddingTop: theme.spacing(8),
		},
		packageSection: {
			marginTop: theme.spacing(2),
		},
		packageContainer: {
			padding: theme.spacing(4),
			transition: theme.transitions.create(),

			'&:hover': {
				boxShadow: theme.shadows[6],
			},
		},
		packageLink: {
			textDecoration: 'none',
		},
		packageButton: {
			marginTop: theme.spacing(3),
		},
		currencySymbol: {
			marginTop: theme.spacing(),
		},
		price: {
			fontWeight: 800,
		},
		faqSection: {
			marginTop: theme.spacing(8),
		},
		features: {
			marginTop: theme.spacing(4),
		},
	}),
	{ name: 'EnterprisePage' }
)

const EnterprisePage = () => {
	const classes = useStyles()

	const renderFaq = (item, index) => (
		<Grid item key={index}>
			<Typography gutterBottom variant="h6">
				{item.question}
			</Typography>
			<Typography component="div" color="textSecondary" variant="body1">
				<MarkdownRenderer>{item.answer}</MarkdownRenderer>
			</Typography>
		</Grid>
	)

	const faqHalfCount = round(size(faqs) / 2)

	return (
		<Layout>
			<Container className={classes.root}>
				<Helmet>
					<title>Enterprise</title>
				</Helmet>
				<section>
					<Typography align="center" gutterBottom variant="h3">
						Fully-customizable task management
					</Typography>

					<Typography align="center" gutterBottom variant="subtitle1">
						Tyto provides a fully-customizable task integration to
						your team, pulling together data from multiple sources
						and multiple channels. This allows your computer systems
						and staff to interact seamlessly, handing off tasks to
						team members to the get next step done— and then back to
						into your system.
					</Typography>
				</section>

				<Grid container className={classes.faqSection} spacing={5}>
					<Grid item xs={12} md={6}>
						<Grid container spacing={5}>
							{map(slice(faqs, 0, faqHalfCount), renderFaq)}
						</Grid>
					</Grid>
					<Grid item xs={12} md={6}>
						<Grid container spacing={5}>
							{map(slice(faqs, faqHalfCount), renderFaq)}
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</Layout>
	)
}

export default EnterprisePage
